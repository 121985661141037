import { ActivityIndicator, Icon, IconButton } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import Toast from 'react-native-root-toast'
import { useCallback } from 'react'
import { MessageType } from '../utils/message-type'
import { useExportDocxLazyQuery } from '../generated/graphql'
import { Article } from '../screens/Perspective/types'
import { downloadFile } from '../utils/import-utils'
import { useErrorTranslation } from '../utils/error-utils'
import { TouchableIconWithToolTip } from '../utils/meldd-tooltip'
import { useAuthContext } from '../auth/auth-context'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../navigation/types'

interface ExportToDocxButtonProps {
  perspective: Article
  size?: number
}

export function ExportToDocxButton({
  perspective,
  size = 24,
}: ExportToDocxButtonProps) {
  const { t } = useTranslation('articles')
  const [exportDocx, { loading }] = useExportDocxLazyQuery()
  const { authAction } = useAuthContext()
  const navigator = useNavigation<AppNavigation>()
  const tError = useErrorTranslation()
  const handleExport = useCallback(async () => {
    try {
      const result = await exportDocx({ variables: { id: perspective.id } })
      if (!result.data || result.error) {
        Toast.show(tError(result.error) || t('export-error'), MessageType.error)
        return
      }
      const downloadResult = await downloadFile(
        result.data.exportDocx,
        `${perspective.title}.docx`
      )
      if (!downloadResult) {
        Toast.show(t('export-error'), MessageType.error)
        return
      }
      Toast.show(t('export-success'), MessageType.info)
    } catch (error) {
      Toast.show((error as Error)?.message, MessageType.error)
    }
  }, [perspective, exportDocx, t])

  if (loading) {
    return <ActivityIndicator size="small" />
  }
  return (
    <TouchableIconWithToolTip
      icon={<Icon source="export" size={size} />}
      onPress={() => authAction(navigator, handleExport)}
      disabled={loading}
      tooltip={t('export')}
    />
  )
}
